import React from 'react'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import PageHeader from '../components/page-header'

const NotFoundPage = () => (
  <Layout startingClass="color-switch--white">
    <section className="s-container s-page__hero">
      <PageHeader
        lede="We can't find this page."
        subheading="Please click the links above to see our current pages."
      />
    </section>
    <Prefooter />
  </Layout>
)

export default NotFoundPage
