export default [
  {
    slug: '/services/design-new-product/',
    title: 'Design a new product',
  },
  {
    slug: '/services/improve-existing-product/',
    title: 'Improve an existing product',
  },
  {
    slug: '/services/launch-new-company/',
    title: 'Launch a new company',
  },
  {
    slug: '/services/project-based-consulting/',
    title: 'Product consulting',
  },
  {
    slug: '/services/strategic-design',
    title: 'Strategic design'
  },
  {
    slug: '/services/education-workshops',
    title: 'Education'
  }
]
