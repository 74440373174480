import React from 'react'

import { useI18next, Link } from 'gatsby-plugin-react-i18next'

import Block from '../waypoint-block'

import menuItems from '../../data/menu-links'

export default function Prefooter() {
  const { t } = useI18next()
  return (
    <div>
      <Block>
        <section className="s-container s-section grid grid--2col-1-1 s-prefooter">
          <div>
            <h4>
              <span className="color-secondary">
                {t('ServicesList.heading')}
              </span>
              <br />
              {t('ServicesList.subheading')}
            </h4>
          </div>
          <ol>
            {menuItems.map(({ slug, title }, index) => {
              return (
                <li key={index}>
                  <Link to={slug}>
                    {t('ServicesList.services.' + index + '.name')}
                  </Link>
                </li>
              )
            })}
          </ol>
        </section>
      </Block>
    </div>
  )
}
